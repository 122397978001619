import React from 'react';
import { Link } from '@reach/router';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const styleFn = theme => ({
  title: {
    color: 'inherit',
    textDecoration: 'none',
    flexGrow: 1,
    textAlign: 'center',
  },
});

function CustomAppBar(props) {
  const { classes, className, onMenuClicked, loggedIn, onLoginClick, onLogoutClick } = props;

  return (
    <AppBar className={className} position="fixed">
      <Toolbar>
        <IconButton edge="start" onClick={onMenuClicked} color="inherit" aria-label="menu">
          <MenuIcon />
        </IconButton>
        <Link className={classes.title} to="/">
          <Typography variant="h4">fuelstop</Typography>
        </Link>
        {loggedIn ? (
          <IconButton edge="end" color="inherit" aria-label="logout" onClick={onLogoutClick}>
            <ExitToAppIcon />
          </IconButton>
        ) : (
          <IconButton edge="end" color="inherit" aria-label="login" onClick={onLoginClick}>
            <VpnKeyIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default withStyles(styleFn)(CustomAppBar);
