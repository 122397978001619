import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'animate.css/animate.min.css';
import formulaSvg from '../resources/racing-icons/svg/022-formula one.svg';
import dragSvg from '../resources/racing-icons/svg/014-drag racing.svg';
import rallySvg from '../resources/racing-icons/svg/045-rally.svg';
import sportSvg from '../resources/racing-icons/svg/051-sport car.svg';
import kartSvg from '../resources/racing-icons/svg/027-kart.svg';
import burnoutSvg from '../resources/racing-icons/svg/007-burnout.svg';
import 'animate.css/animate.min.css';
import BigNumber from 'bignumber.js';
import TransactionQueue from '../components/transactionQueue';

const styleFn = theme => ({
  car: {
    position: 'absolute',
    animationName: '$drive',
    animationTimingFunction: 'linear',
    height: '50%',
    right: '-50vw',
  },
  '@keyframes drive': {
    from: { transform: 'translate(-200vw, 0)' },
    to: { transform: 'translate(0, 0)' },
  },
  pavement: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.grey[800],
  },
  lines: {
    position: 'absolute',
    top: 0,
    height: '100%',
    width: '100%',
  },
  line: {
    position: 'absolute',
    height: '5%',
  },
  lineSvg: {
    position: 'absolute',
    top: '0',
    height: '100%',
    width: '4000px',
  },
  dashedPath: {
    fill: 'none',
    stroke: theme.palette.background.default,
    strokeWidth: '1',
    strokeDasharray: '3, 5',
  },
  solidPath: {
    fill: 'none',
    stroke: theme.palette.primary.main,
    strokeWidth: '1',
  },
  cars: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
});

const getCar = speed => {
  const coinToss = Math.round(Math.random());

  if (speed === 10) return formulaSvg;
  if (speed === 9) return formulaSvg;
  if (speed === 8) return coinToss ? dragSvg : formulaSvg;
  if (speed === 7) return dragSvg;
  if (speed === 6) return coinToss ? rallySvg : dragSvg;
  if (speed === 5) return rallySvg;
  if (speed === 4) return coinToss ? sportSvg : rallySvg;
  if (speed === 3) return sportSvg;
  if (speed === 2) return coinToss ? kartSvg : sportSvg;
  return kartSvg;
};

const baseSpeed = 0.05;

const getDuration = (speed, trackLength) => trackLength / (speed * baseSpeed);

const emptyTransactions = {
  isEmpty: true,
  pop: () => ({}),
  stop: () => null,
};

const setStatefulTimeout = (callback, duration) => {
  const statefulTimeout = { done: false };

  const timeout = setTimeout(() => {
    statefulTimeout.done = true;
    callback();
  }, duration);

  statefulTimeout.clear = () => clearTimeout(timeout);

  return statefulTimeout;
};

function Track(props) {
  const { classes, className, getTransactions, blastAway } = props;

  const { innerWidth } = window;

  const [canBlast, setCanBlast] = React.useState(true);
  const [reCheck, setReCheck] = React.useState(false);

  const [track1, setTrack1] = React.useState(null);
  const [track2, setTrack2] = React.useState(null);
  const [track3, setTrack3] = React.useState(null);
  const [track4, setTrack4] = React.useState(null);
  const [track5, setTrack5] = React.useState(null);

  const [timeouts] = React.useState([]);

  const [transactions, setTransactions] = React.useState(emptyTransactions);

  React.useEffect(() => {
    const txQueue = new TransactionQueue(getTransactions);
    setTransactions(txQueue);

    return () => {
      timeouts.forEach(timeout => timeout.clear());
      txQueue.stop();
    };
  }, []);

  if (!canBlast && !blastAway) setCanBlast(true);

  const tracks = [
    { track: track1, setTrack: setTrack1 },
    { track: track2, setTrack: setTrack2 },
    { track: track3, setTrack: setTrack3 },
    { track: track4, setTrack: setTrack4 },
    { track: track5, setTrack: setTrack5 },
  ];

  const numberOfTracks = tracks.length;
  const laneWidth = 95 / numberOfTracks - 5;

  const updateManagedTimeout = newTimeouts => {
    for (let i = 0; i < timeouts.length; i++) {
      const timeout = timeouts.shift();

      if (!timeout.done) timeouts.push(timeout);
    }

    timeouts.push(...newTimeouts);
  };

  const trackTimeouts = tracks
    .map(({ track, setTrack }) => {
      if (track) return;

      if (canBlast && blastAway) {
        const duration = getDuration(5, innerWidth);
        setTrack({ txId: 'user', duration, model: burnoutSvg });
        setCanBlast(false);
        return setStatefulTimeout(() => setTrack(null), duration + 300);
      }

      if (transactions.isEmpty) return;

      const { txId, gasPrice } = transactions.pop();
      const speed = BigNumber(gasPrice)
        .dividedBy(1000000000)
        .toNumber();
      const duration = getDuration(speed, innerWidth);

      setTrack({ txId, duration, model: getCar(speed) });
      return setStatefulTimeout(() => setTrack(null), duration + 300);
    })
    .filter(Boolean);

  if (trackTimeouts.length) updateManagedTimeout(trackTimeouts);

  const noMoreCars = tracks.every(({ track }) => !track);

  if (noMoreCars) setStatefulTimeout(() => setReCheck(!reCheck), 1000);

  return (
    <div className={className}>
      <div className={classes.pavement}></div>

      <div className={classes.lines}>
        <div className={classes.line} style={{ top: 0 }}>
          <svg className={classes.lineSvg} viewBox="0 0 300 5" preserveAspectRatio="none">
            <path className={classes.solidPath} d="M0,3 300,3" />
          </svg>
        </div>

        {tracks.map((_, i) => {
          if (i === tracks.length - 1) return null;

          return (
            <div
              key={i}
              className={classes.line}
              style={{ top: `${5 + laneWidth + i * (laneWidth + 5)}%` }}
            >
              <svg
                className={classes.lineSvg}
                viewBox="0 0 300 5"
                preserveAspectRatio="none"
                style={{ left: -i * 15 }}
              >
                <path className={classes.dashedPath} d="M0,3 300,3" />
              </svg>
            </div>
          );
        })}

        <div className={classes.line} style={{ bottom: 0 }}>
          <svg className={classes.lineSvg} viewBox="0 0 300 5" preserveAspectRatio="none">
            <path className={classes.solidPath} d="M0,3 300,3" />
          </svg>
        </div>
      </div>

      <div className={classes.cars}>
        {tracks.map(({ track, setTrack }, i) => {
          const txId = track ? track.txId : null;

          if (!txId) return null;

          const style = {
            animationDuration: `${track.duration}ms`,
            top: `${i * (laneWidth + 5) - 20}%`,
          };
          return (
            <img
              key={txId}
              className={classes.car}
              src={track.model}
              style={style}
              alt="Car racing down speedway"
            />
          );
        })}
      </div>
    </div>
  );
}

export default withStyles(styleFn)(Track);
