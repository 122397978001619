import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import copy from 'copy-text-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';

// Variables to control the truncation behavior
const startFixedChars = 4; // Number of chars before ellipsis - have priority over end chars
const endFixedChars = 4; // Number of chars after ellipsis  - lower priority than start chars
const fontFaceScaleFactor = 0.46; // Magic number dependent on font face - set by trial and error

// Derived from the 3 variables above
const startWidth = fontFaceScaleFactor * (startFixedChars + 3);
const endWidth = fontFaceScaleFactor * endFixedChars;

const styleFn = theme => ({
  root: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.default,
    borderRadius: '100px',
    padding: '0 8px 0 8px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#ccc',
  },
  chipText: {
    flex: '1 1 0',
    minWidth: '10ch',
    padding: '8px 12px',
    textDecoration: 'none',
    color: 'inherit',
  },
  firstPart: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: `calc(100% - (1em * ${endWidth}))`,
    minWidth: startWidth,
    textOverflow: 'ellipsis',
  },
  lastPart: {
    display: 'inline-block',
    verticalAlign: 'bottom',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth: `calc(100% - (1em * ${startWidth}))`,
    direction: 'rtl',
  },
  copyButton: {
    flex: '0 0 0',
  },
});

function Chip(props) {
  const { classes, className, value } = props;
  const lastPart = value.slice(-4);
  const firstPart = value.substr(0, value.length - 3);

  const handleClick = e => {
    e.stopPropagation();
    copy(value);
  };

  return (
    <div className={clsx(classes.root, className)}>
      <a
        className={classes.chipText}
        href={`https://etherscan.io/search?f=0&q=${value}`}
        target="_blank"
      >
        <Typography className={classes.firstPart}>{firstPart}</Typography>
        <Typography className={classes.lastPart}>{lastPart}</Typography>
      </a>
      <IconButton className={classes.copyButton} onClick={handleClick} color="inherit">
        <FileCopyIcon />
      </IconButton>
    </div>
  );
}

export default withStyles(styleFn)(Chip);
