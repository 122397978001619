/**
 * @fileoverview gRPC-Web generated client stub for squishy
 * @enhanceable
 * @public
 */

// GENERATED CODE -- DO NOT EDIT!


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.squishy = require('./squishy_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.squishy.ProjectClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?Object} options
 * @constructor
 * @struct
 * @final
 */
proto.squishy.ProjectPromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options['format'] = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname;

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.squishy.ListAddressesRequest,
 *   !proto.squishy.ListAddressesResponse>}
 */
const methodDescriptor_Project_ListAddresses = new grpc.web.MethodDescriptor(
  '/squishy.Project/ListAddresses',
  grpc.web.MethodType.UNARY,
  proto.squishy.ListAddressesRequest,
  proto.squishy.ListAddressesResponse,
  /**
   * @param {!proto.squishy.ListAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.ListAddressesResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.squishy.ListAddressesRequest,
 *   !proto.squishy.ListAddressesResponse>}
 */
const methodInfo_Project_ListAddresses = new grpc.web.AbstractClientBase.MethodInfo(
  proto.squishy.ListAddressesResponse,
  /**
   * @param {!proto.squishy.ListAddressesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.ListAddressesResponse.deserializeBinary
);


/**
 * @param {!proto.squishy.ListAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.squishy.ListAddressesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.squishy.ListAddressesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.squishy.ProjectClient.prototype.listAddresses =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/squishy.Project/ListAddresses',
      request,
      metadata || {},
      methodDescriptor_Project_ListAddresses,
      callback);
};


/**
 * @param {!proto.squishy.ListAddressesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.squishy.ListAddressesResponse>}
 *     A native promise that resolves to the response
 */
proto.squishy.ProjectPromiseClient.prototype.listAddresses =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/squishy.Project/ListAddresses',
      request,
      metadata || {},
      methodDescriptor_Project_ListAddresses);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.squishy.AddAddressRequest,
 *   !proto.squishy.AddAddressResponse>}
 */
const methodDescriptor_Project_AddAddress = new grpc.web.MethodDescriptor(
  '/squishy.Project/AddAddress',
  grpc.web.MethodType.UNARY,
  proto.squishy.AddAddressRequest,
  proto.squishy.AddAddressResponse,
  /**
   * @param {!proto.squishy.AddAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.AddAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.squishy.AddAddressRequest,
 *   !proto.squishy.AddAddressResponse>}
 */
const methodInfo_Project_AddAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.squishy.AddAddressResponse,
  /**
   * @param {!proto.squishy.AddAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.AddAddressResponse.deserializeBinary
);


/**
 * @param {!proto.squishy.AddAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.squishy.AddAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.squishy.AddAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.squishy.ProjectClient.prototype.addAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/squishy.Project/AddAddress',
      request,
      metadata || {},
      methodDescriptor_Project_AddAddress,
      callback);
};


/**
 * @param {!proto.squishy.AddAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.squishy.AddAddressResponse>}
 *     A native promise that resolves to the response
 */
proto.squishy.ProjectPromiseClient.prototype.addAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/squishy.Project/AddAddress',
      request,
      metadata || {},
      methodDescriptor_Project_AddAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.squishy.RemoveAddressRequest,
 *   !proto.squishy.RemoveAddressResponse>}
 */
const methodDescriptor_Project_RemoveAddress = new grpc.web.MethodDescriptor(
  '/squishy.Project/RemoveAddress',
  grpc.web.MethodType.UNARY,
  proto.squishy.RemoveAddressRequest,
  proto.squishy.RemoveAddressResponse,
  /**
   * @param {!proto.squishy.RemoveAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.RemoveAddressResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.squishy.RemoveAddressRequest,
 *   !proto.squishy.RemoveAddressResponse>}
 */
const methodInfo_Project_RemoveAddress = new grpc.web.AbstractClientBase.MethodInfo(
  proto.squishy.RemoveAddressResponse,
  /**
   * @param {!proto.squishy.RemoveAddressRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.RemoveAddressResponse.deserializeBinary
);


/**
 * @param {!proto.squishy.RemoveAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.squishy.RemoveAddressResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.squishy.RemoveAddressResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.squishy.ProjectClient.prototype.removeAddress =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/squishy.Project/RemoveAddress',
      request,
      metadata || {},
      methodDescriptor_Project_RemoveAddress,
      callback);
};


/**
 * @param {!proto.squishy.RemoveAddressRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.squishy.RemoveAddressResponse>}
 *     A native promise that resolves to the response
 */
proto.squishy.ProjectPromiseClient.prototype.removeAddress =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/squishy.Project/RemoveAddress',
      request,
      metadata || {},
      methodDescriptor_Project_RemoveAddress);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.squishy.ListTransactionsRequest,
 *   !proto.squishy.ListTransactionsResponse>}
 */
const methodDescriptor_Project_ListTransactions = new grpc.web.MethodDescriptor(
  '/squishy.Project/ListTransactions',
  grpc.web.MethodType.UNARY,
  proto.squishy.ListTransactionsRequest,
  proto.squishy.ListTransactionsResponse,
  /**
   * @param {!proto.squishy.ListTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.ListTransactionsResponse.deserializeBinary
);


/**
 * @const
 * @type {!grpc.web.AbstractClientBase.MethodInfo<
 *   !proto.squishy.ListTransactionsRequest,
 *   !proto.squishy.ListTransactionsResponse>}
 */
const methodInfo_Project_ListTransactions = new grpc.web.AbstractClientBase.MethodInfo(
  proto.squishy.ListTransactionsResponse,
  /**
   * @param {!proto.squishy.ListTransactionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.squishy.ListTransactionsResponse.deserializeBinary
);


/**
 * @param {!proto.squishy.ListTransactionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.Error, ?proto.squishy.ListTransactionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.squishy.ListTransactionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.squishy.ProjectClient.prototype.listTransactions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/squishy.Project/ListTransactions',
      request,
      metadata || {},
      methodDescriptor_Project_ListTransactions,
      callback);
};


/**
 * @param {!proto.squishy.ListTransactionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.squishy.ListTransactionsResponse>}
 *     A native promise that resolves to the response
 */
proto.squishy.ProjectPromiseClient.prototype.listTransactions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/squishy.Project/ListTransactions',
      request,
      metadata || {},
      methodDescriptor_Project_ListTransactions);
};


module.exports = proto.squishy;

