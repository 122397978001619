const noop = () => {};

const capitalize = str => str[0].toUpperCase() + str.slice(1);

const requestDataToRequest = (Request, requestData) => {
  const request = new Request();

  for (const key in requestData) {
    request[`set${capitalize(key)}`](requestData[key]);
  }

  return request;
};

export default class GrpcService {
  constructor(GrpcPromiseClient, pb, url, interceptors = {}) {
    this.client = new GrpcPromiseClient(url, null, interceptors);
    this.pb = pb;
  }

  execUnaryCall(method, requestData, metadata) {
    const Request = this.pb[`${capitalize(method)}Request`];
    const request = requestDataToRequest(Request, requestData);

    return this.client[method](request, metadata).then(response => response.toObject());
  }

  execServerStream(method, requestData, metadata, callbacks = {}) {
    const Request = this.pb[`${capitalize(method)}Request`];
    const request = requestDataToRequest(Request, requestData);

    const stream = this.client[method](request, metadata);
    const onData = callbacks.onData || noop;

    stream.on('data', response => onData(response.toObject()));
    stream.on('status', callbacks.onStatus || noop);
    stream.on('end', callbacks.onEnd || noop);

    return { cancel: () => stream.cancel() };
  }
}
