import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Router } from '@reach/router';
import { makeStyles, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import Landing from './pages/landing';
import Project from './pages/project';
import AppBar from './components/appBar';
import AppDrawer from './components/appDrawer';
import Login from './components/login';
import Toolbar from '@material-ui/core/Toolbar';
import configTheme from './configs/theme';
import createApiServices from './modules/api';
import BlockService from './modules/block-service';

const muiTheme = createMuiTheme(configTheme);

const useStyles = makeStyles(() => ({
  appBar: {
    backgroundColor: muiTheme.palette.primary.main,
    zIndex: muiTheme.zIndex.drawer + 1,
  },
  drawer: {
    width: 'max(25%, 200px)',
    backgroundColor: muiTheme.palette.primary.main,
  },
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  spacerBar: {
    flex: '0',
    position: 'relative',
  },
  router: {
    flex: '1',
    position: 'relative',
    minHeight: 0,
  },
  dialogTitle: {
    textAlign: 'center',
  },
}));

const { loginService, projectService, userService, blockService } = createApiServices(
  `http://${window.location.hostname}:8080`,
);

export default function App() {
  const classes = useStyles(muiTheme);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [loggedIn, setLoggedIn] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [hasLoaded, setHasLoaded] = React.useState(false);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  const toggleLogin = () => setDialogOpen(!dialogOpen);

  const handleLogin = googleResponse => {
    try {
      const { error } = googleResponse;
      if (error) {
        throw error;
      }

      const token = googleResponse.getAuthResponse().id_token;

      return loginService.googleAuthenticate({ token }).then(() => {
        setLoggedIn(true);
        toggleLogin(false);
      });
    } catch (err) {
      // TODO: Handle failed login
      console.error(err);

      setLoggedIn(false);
      toggleLogin(true);
    }
  };

  const handleLogout = () => {
    setLoggedIn(false);
  };

  React.useEffect(() => {
    if (!hasLoaded) {
      loginService
        .refreshAccessToken({})
        .then(() => {
          setLoggedIn(true);
        })
        .catch(() => {
          setLoggedIn(false);
        });
      setHasLoaded(true);
    }
  });

  return (
    <MuiThemeProvider theme={muiTheme}>
      <CssBaseline />
      <AppBar
        className={classes.appBar}
        onMenuClicked={toggleDrawer}
        loggedIn={loggedIn}
        onLoginClick={toggleLogin}
        onLogoutClick={handleLogout}
      />
      <AppDrawer
        className={classes.drawer}
        drawerOpen={!dialogOpen && drawerOpen}
        onCloseClick={toggleDrawer}
        loggedIn={loggedIn}
        onLoginClick={toggleLogin}
        onLogoutClick={handleLogout}
      />
      <div className={classes.container}>
        <Toolbar className={classes.spacerBar} />
        <Router className={classes.router}>
          <Landing path="/" getTransactions={callback => blockService.getTransactions(callback)} />
          <Project
            path="/project"
            getProject={() => userService.listProjects()}
            getAddresses={() => projectService.listAddresses()}
            addAddress={address => projectService.addAddress(address)}
            removeAddress={address => projectService.removeAddress(address)}
            getTransactions={address => projectService.listTransactions(address)}
            addCard={() => {}}
            removeCard={() => {}}
          />
        </Router>
      </div>
      <Dialog onClose={toggleLogin} open={dialogOpen}>
        <DialogTitle className={classes.dialogTitle}>Login Options</DialogTitle>
        <Login login={handleLogin} />
      </Dialog>
    </MuiThemeProvider>
  );
}
