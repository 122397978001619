import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import 'animate.css/animate.min.css';
import gasStationSvg from '../resources/logo.svg';
import clsx from 'clsx';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import Typography from '@material-ui/core/Typography';
import carSvg from '../resources/racing-icons/svg/051-sport car.svg';
import pitStopSvg from '../resources/racing-icons/svg/036-pit stop.svg';
import telemetrySvg from '../resources/racing-icons/svg/058-telemetry.svg';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import Box from '@material-ui/core/Box';
import { animateScroll as scroll } from 'react-scroll';
import Track from '../components/track';
import Link from '@material-ui/core/Link';
import Login from '../components/login';

// TODO: end/clear TransactionQueue on dismount

const debugMode = false;

const startingHeaderRatio = 0.85;
const middleHeaderRatio = 0.5;
const endHeaderRatio = 0.3;
const numberOfPanels = 7;

const styleFn = theme => ({
  debug: {
    fontSize: '12px',
    position: 'fixed',
    zIndex: 1200,
    bottom: 0,
    display: debugMode ? 'block' : 'none',
  },
  hidden: {
    visibility: 'hidden',
  },
  removed: {
    display: 'none',
  },
  root: {
    height: '100%',
    position: 'relative',
  },
  header: {
    position: 'fixed',
    zIndex: 100,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `3px solid ${theme.palette.secondary.dark}`,
    overflow: 'hidden',
  },
  pump: {
    position: 'absolute',
    width: '100%',
    height: 'min(15vh, 30%)',
  },
  title: {
    position: 'absolute',
    width: '100%',
    top: '75%',
    color: theme.palette.common.highlight,
    textStroke: `2px ${theme.palette.common.lowlight}`,
  },
  subtitle: {
    position: 'absolute',
    width: '100%',
    top: '40%',
    color: theme.palette.common.lowlight,
  },
  altTitle: {
    position: 'absolute',
    width: '100%',
    top: '35%',
    color: theme.palette.common.highlight,
    textStroke: `2px ${theme.palette.common.lowlight}`,
  },
  publicTrack: {
    position: 'absolute',
    top: '5%',
    width: '100%',
    height: '25%',
  },
  ourTrack: {
    position: 'absolute',
    bottom: '5%',
    height: '20%',
    width: '100%',
    backgroundColor: theme.palette.grey[800],
  },
  pitstop: {
    position: 'absolute',
    bottom: '100%',
    left: '65%',
    height: '150%',
  },
  ourCar: {
    position: 'absolute',
    height: '50%',
    bottom: '-7%',
    left: '40%',
  },
  telemetry: {
    position: 'absolute',
    bottom: '30%',
    right: '65%',
    height: '20%',
  },
  streetLine: {
    position: 'relative',
    height: '50%',
    width: '100%',
    overflow: 'hidden',
  },
  streetLineSvg: {
    position: 'absolute',
    top: '0px',
    height: '100%',
    width: '4000px',
  },
  streetLinePath: {
    fill: 'none',
    stroke: '#fff',
    strokeWidth: '20',
    strokeDasharray: '4, 7',
  },
  upArrow: {
    position: 'fixed',
    height: '5%',
    width: '100%',
    color: theme.palette.common.highlight,
    zIndex: 1000,
    cursor: 'pointer',
  },
  downArrow: {
    position: 'fixed',
    bottom: 0,
    height: '5%',
    width: '100%',
    color: theme.palette.common.highlight,
    zIndex: 1000,
    cursor: 'pointer',
  },
  panel: {
    position: 'relative',
    backgroundColor: theme.palette.background.default,
    padding: '0 5vw',
  },
  startPanel: {
    height: '100%',
  },
  midPanel: {
    height: `${100 * (1 - middleHeaderRatio)}%`,
  },
  endPanel: {
    height: `${100 * (1 - endHeaderRatio)}%`,
  },
  panelContent: {
    position: 'relative',
    color: theme.palette.text.primary,
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  iconCredit: {
    backgroundColor: theme.palette.grey[800],
    color: 'white',
    padding: '5px 0',
  },
  link: {
    color: theme.palette.common.highlight,
  },
});

function Landing(props) {
  const { classes, getTransactions } = props;

  // Patented "Brower Vendors are Idiots" Buffer
  const bvaiBuffer = document.body.clientHeight - window.innerHeight;

  useScrollPosition(({ prevPos, currPos }) => setScrollOffset(-1 * currPos.y));

  const [initialized, setInitialized] = React.useState(false);
  const [scrollOffset, setScrollOffset] = React.useState(0);
  const [droveOff, setDroveOff] = React.useState(false);

  const thisPage = document.getElementById('page-landing');
  const pageHeight = thisPage ? thisPage.clientHeight : 1;
  const panelHeight = pageHeight * middleHeaderRatio;
  const endPanelHeight = pageHeight * (1 - endHeaderRatio);

  const regularPanelsScrolled = Math.floor((scrollOffset - bvaiBuffer) / panelHeight);
  const endPanelScolled = scrollOffset - 6 * panelHeight - bvaiBuffer > endPanelHeight;
  const currentPage =
    scrollOffset === 0
      ? -1
      : regularPanelsScrolled < 6
      ? regularPanelsScrolled
      : endPanelScolled
      ? 7
      : 6;

  const localScrollOffset =
    currentPage === -1
      ? 0
      : currentPage === 6
      ? (scrollOffset - 6 * panelHeight - bvaiBuffer) % endPanelHeight
      : (scrollOffset - bvaiBuffer) % panelHeight;

  if (!initialized) setTimeout(() => setInitialized(true), 1000);

  const getStageHeight = stage => {
    if (stage === 1) return startingHeaderRatio * pageHeight - scrollOffset;
    if (stage === 2) return pageHeight * middleHeaderRatio;
    if (stage === 3) return middleHeaderRatio * pageHeight - (localScrollOffset % panelHeight);
    if (stage === 4) return pageHeight * endHeaderRatio;
  };

  const firstStageComplete =
    startingHeaderRatio * pageHeight < middleHeaderRatio * pageHeight + scrollOffset;
  const secondStageComplete =
    currentPage === 7 || (currentPage === 6 && localScrollOffset >= panelHeight);
  const thirdStageComplete = currentPage === 7;
  const headerHeight = !firstStageComplete
    ? getStageHeight(1)
    : !secondStageComplete
    ? getStageHeight(2)
    : !thirdStageComplete
    ? getStageHeight(3)
    : getStageHeight(4);

  // console.log('currentPage', currentPage, 'stage', 1 + firstStageComplete + secondStageComplete + thirdStageComplete);

  const getPumpBottom = stage => {
    if (stage === 1)
      return 27 - 7 * (scrollOffset / (pageHeight * (startingHeaderRatio - middleHeaderRatio)));
    if (stage === 2) return 20;
    if (stage === 3)
      return (
        20 -
        10 *
          ((localScrollOffset % panelHeight) / (pageHeight * (middleHeaderRatio - endHeaderRatio)))
      );
    if (stage === 4) return 10;
  };

  const pumpBottom = !firstStageComplete
    ? getPumpBottom(1)
    : !secondStageComplete
    ? getPumpBottom(2)
    : !thirdStageComplete
    ? getPumpBottom(3)
    : getPumpBottom(4);

  if (!droveOff && currentPage >= 5) setDroveOff(true);

  if (droveOff && currentPage === 0) setDroveOff(false);

  const viewHeight = pageHeight - headerHeight;
  const viewPercent = localScrollOffset / panelHeight;

  const titleInView = currentPage === -1 || (currentPage === 0 && viewPercent < 0.2);
  const ourTrackInView =
    (currentPage === 0 && viewPercent > 0.5) ||
    (currentPage > 0 && currentPage < 6) ||
    (currentPage === 6 && viewPercent < 0.75);
  const carInView =
    (currentPage === 1 && viewPercent > 0.5) ||
    (currentPage > 1 && currentPage < 5) ||
    (currentPage === 5 && viewPercent < 0.25);
  const telemetryInView =
    (currentPage === 2 && viewPercent > 0.5) || (currentPage === 3 && viewPercent < 0.25);
  const altTitleInView = ourTrackInView || secondStageComplete;

  const upArrowInView = currentPage > -1;
  const downArrowInView = currentPage < 6;

  const getPageScrollPostion = page => {
    return page === -1
      ? 0
      : (page + 1) * panelHeight +
          (page === 6) * (middleHeaderRatio - endHeaderRatio) * pageHeight +
          bvaiBuffer / 2 -
          1;
  };

  const scrollToNextPanel = () => {
    const nextPage = currentPage === -1 ? 0 : currentPage + (viewPercent > 0.75);
    const currenPosition = document.documentElement.scrollTop;
    const newPosition = getPageScrollPostion(nextPage);
    const duration = Math.abs(newPosition - currenPosition);
    scroll.scrollTo(newPosition, { duration, delay: 100, smooth: 'easeInOutQuad' });
  };

  const scrollToPreviousPanel = () => {
    const previousPage = currentPage - 1 - (viewPercent < 0.15);
    const currenPosition = document.documentElement.scrollTop;
    const newPosition = getPageScrollPostion(previousPage);
    const duration = Math.abs(newPosition - currenPosition);
    scroll.scrollTo(newPosition, { duration, delay: 100, smooth: 'easeInOutQuad' });
  };

  return (
    <div id="page-landing" className={classes.root}>
      <div
        className={classes.debug}
      >{`b:${bvaiBuffer} pg:${pageHeight} pn:${panelHeight} hh:${Math.ceil(
        headerHeight,
      )} so:${Math.ceil(scrollOffset)} cp:${currentPage} lo:${Math.ceil(
        localScrollOffset,
      )} vh:${Math.ceil(viewHeight)} v%:${Math.ceil(viewPercent * 100)}`}</div>

      <ArrowUpwardIcon
        className={clsx(classes.upArrow, !upArrowInView && classes.removed)}
        onClick={scrollToPreviousPanel}
        style={{ marginTop: headerHeight }}
      />
      <ArrowDownwardIcon
        className={clsx(classes.downArrow, !downArrowInView && classes.removed)}
        onClick={scrollToNextPanel}
      />

      <Box className={classes.header} style={{ height: headerHeight }}>
        <Track
          className={classes.publicTrack}
          getTransactions={getTransactions}
          blastAway={droveOff}
        />
        <div
          className={clsx(
            classes.ourTrack,
            !initialized && classes.hidden,
            'animated',
            ourTrackInView && 'slideInLeft',
            !ourTrackInView && 'slideOutRight',
          )}
        >
          <img
            className={clsx(
              classes.pitstop,
              !initialized && classes.hidden,
              'animated',
              ourTrackInView && 'slideInLeft',
              !ourTrackInView && 'slideOutRight',
            )}
            src={pitStopSvg}
            alt="Fuel Stop Signage"
          />
          <div className={classes.streetLine}>
            <svg
              className={classes.streetLineSvg}
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              style={{ left: 0 }}
            >
              <path className={classes.streetLinePath} d="M0,100 100,100" />
            </svg>
          </div>
        </div>
        <img
          className={clsx(
            classes.telemetry,
            !initialized && classes.hidden,
            'animated',
            telemetryInView && 'fadeInRight',
            !telemetryInView && 'fadeOutRight',
          )}
          src={telemetrySvg}
          alt="Telemetry"
        />
        <img
          className={classes.pump}
          src={gasStationSvg}
          alt="Gas Station Pump"
          style={{ bottom: `${pumpBottom}%` }}
        />
        <img
          className={clsx(
            classes.ourCar,
            !initialized && classes.hidden,
            'animated',
            carInView && 'bounceInLeft',
            !carInView && 'bounceOutRight',
          )}
          src={carSvg}
          alt="Sports Car Refueling"
        />
        <div
          className={clsx(
            classes.title,
            'animated',
            titleInView && 'bounceInLeft',
            !titleInView && 'bounceOutRight',
          )}
        >
          <Typography variant="h1" align="center">
            fuelstop
          </Typography>
        </div>
        <div
          className={clsx(
            classes.subtitle,
            'animated',
            titleInView && 'bounceInLeft',
            !titleInView && 'bounceOutRight',
          )}
        >
          <Typography variant="h4" align="center">
            <i>Your transactions</i>
          </Typography>
          <Typography variant="h4" align="center">
            <i>Our gas</i>
          </Typography>
        </div>
        <div
          className={clsx(
            classes.altTitle,
            !initialized && classes.hidden,
            'animated',
            altTitleInView && 'slideInLeft',
            !altTitleInView && 'slideOutRight',
          )}
        >
          <Typography variant="h3" align="center">
            fuelstop
          </Typography>
        </div>
      </Box>

      <div className={clsx(classes.panel, classes.startPanel)}></div>

      <div className={clsx(classes.panel, classes.midPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            Welcome to fuelstop: a pitstop for your blockchain transaction.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.panel, classes.midPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            Send us your signed transaction via the standard node interfaces, as a drop-in
            replacement.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.panel, classes.midPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            We'll check your address to determine if it has enough coin to cover the transaction
            fees.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.panel, classes.midPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            We'll immediately top up your address with enough coin.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.panel, classes.midPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            Then once your address receives the coin, we'll send your transaction off to the
            blockchain.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.panel, classes.midPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            So you can continue to form and broadcast transactions in the same manner, while we
            worry about about the gas costs.
          </Typography>
        </div>
      </div>

      <div className={clsx(classes.panel, classes.endPanel)}>
        <div className={classes.panelContent}>
          <Typography variant="h5" align="center">
            Sign Up!
          </Typography>
          <Login />
        </div>
      </div>

      <Typography className={classes.iconCredit} align="center">
        Icons made by
        <Link
          className={classes.link}
          href="https://www.flaticon.com/authors/flat-icons"
          target="_blank"
          rel="noopener"
        >
          {' '}
          Flat Icons{' '}
        </Link>
        from
        <Link
          className={classes.link}
          href="https://www.flaticon.com/"
          target="_blank"
          rel="noopener"
        >
          {' '}
          www.flaticon.com{' '}
        </Link>
      </Typography>
    </div>
  );
}

export default withStyles(styleFn)(Landing);
