import { BlockPromiseClient } from '../protos/internal_grpc_web_pb';
import internalPb from '../protos/internal_pb';
import GrpcService from './grpc-service';

export default class BlockService extends GrpcService {
  constructor(url) {
    super(BlockPromiseClient, internalPb, url);
  }

  getTransactions(callback) {
    return this.execServerStream(
      this.getTransactions.name,
      {},
      {},
      {
        onData({ transactionsList = [] } = {}) {
          callback(transactionsList);
        },
        onStatus() {},
        onEnd() {},
      },
    ).cancel;
  }
}
