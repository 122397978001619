import LoginService from './login-service';
import UserService from './user-service';
import ProjectService from './project-service';
import BlockService from './block-service';

import { BlockClient, GetTransactionsRequest } from '../protos/internal_grpc_web_pb.js';

export default function createApiServices(url) {
  const context = { metadata: {} };

  return {
    loginService: new LoginService(url, context),
    userService: new UserService(url, context),
    projectService: new ProjectService(url, context),
    blockService: new BlockService(url),
  };
}
