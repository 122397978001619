import { UserPromiseClient } from '../protos/internal_grpc_web_pb';
import internalPb from '../protos/internal_pb';

import GrpcService from './grpc-service';
import RefreshAccessTokenInterceptor from './interceptors/refresh-access-token';

export default class UserService extends GrpcService {
  constructor(url, context) {
    super(UserPromiseClient, internalPb, url, {
      unaryInterceptors: [new RefreshAccessTokenInterceptor(url, context)],
    });
    this.context = context;
  }

  createSetupIntent(request, metadata = {}) {
    return this.execUnaryCall(
      this.createSetupIntent.name,
      request,
      Object.assign(metadata, this.context.metadata),
    );
  }

  deletePaymentMethod(request, metadata = {}) {
    return this.execUnaryCall(
      this.deletePaymentMethod.name,
      request,
      Object.assign(metadata, this.context.metadata),
    );
  }

  listProjects(request, metadata = {}) {
    return this.execUnaryCall(this.listProjects.name, request, metadata);
  }

  topUpAccount(request, metadata = {}) {
    return this.execUnaryCall(
      this.topUpAccount.name,
      request,
      Object.assign(metadata, this.context.metadata),
    );
  }

  updateAccountSettings(request, metadata = {}) {
    return this.execUnaryCall(
      this.updateAccountSettings.name,
      request,
      Object.assign(metadata, this.context.metadata),
    );
  }

  listPaymentDetails(request, metadata = {}) {
    return this.execUnaryCall(
      this.listPaymentDetails.name,
      request,
      Object.assign(metadata, this.context.metadata),
    );
  }
}
