import Queue from 'queue-fifo';

export default class TransactionQueue {
  constructor(getTransactions) {
    this.stopped = false;
    this.queue = new Queue();
    this.pruneBuffer = [];

    this.cancelStream = getTransactions(transactions => {
      if (this.stopped) return;

      transactions.forEach(transaction => this.queue.enqueue(transaction));

      const queueSize = this.queue.size();

      console.log(`Queued Transactions: ${queueSize} (+${transactions.length})`);

      if (queueSize > 400) {
        this.pruneBuffer = this.pruneBuffer.concat(transactions);
      }

      if (queueSize > 500) {
        this.queue.clear();
        this.pruneBuffer.forEach(transaction => this.queue.enqueue(transaction));
        this.pruneBuffer.length = 0;
      }
    });
  }

  pop() {
    return this.queue.dequeue();
  }

  get isEmpty() {
    return this.queue.isEmpty();
  }

  stop() {
    this.stopped = true;
    this.cancelStream();
    this.queue.clear();
  }
}
