import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { GoogleLogin } from 'react-google-login';

const styleFn = theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
  },
});

function Login(props) {
  const { classes, className, toggleDrawer, login } = props;

  return (
    <div className={classes.root}>
      <GoogleLogin
        clientId="90148110385-fh5p26kk64vrmdiqmdq8i35e2s657697.apps.googleusercontent.com"
        buttonText="Sign in with Google"
        onSuccess={login}
        onFailure={login}
        cookiePolicy={'single_host_origin'}
      />
    </div>
  );
}

export default withStyles(styleFn)(Login);
