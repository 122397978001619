import { LoginPromiseClient } from '../protos/internal_grpc_web_pb';
import internalPb from '../protos/internal_pb';

import GrpcService from './grpc-service';

//TODO: put in config
const AUTHORIZATION_HEADER = 'authorization';

export default class LoginService extends GrpcService {
  constructor(url, context) {
    super(LoginPromiseClient, internalPb, url);
    this.context = context;
  }

  googleAuthenticate(request, metadata) {
    return this.execUnaryCall(this.googleAuthenticate.name, request, metadata).then(
      ({ accessToken }) => {
        this.context.metadata = Object.assign(this.context.metadata, {
          [AUTHORIZATION_HEADER]: accessToken,
        });

        return { accessToken };
      },
    );
  }

  githubAuthenticate(request, metadata) {
    return this.execUnaryCall(this.githubAuthenticate.name, request, metadata);
  }

  refreshAccessToken(request, metadata) {
    return this.execUnaryCall(this.refreshAccessToken.name, request, metadata).then(
      ({ accessToken }) => {
        this.context.metadata = Object.assign(this.context.metadata, {
          [AUTHORIZATION_HEADER]: accessToken,
        });

        return { accessToken };
      },
    );
  }

  invalidateRefreshToken(request, metadata) {
    return this.execUnaryCall(this.invalidateRefreshToken.name, request, metadata);
  }
}
