import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloseIcon from '@material-ui/icons/Close';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListIcon from '@material-ui/icons/List';
import PaymentIcon from '@material-ui/icons/Payment';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InfoIcon from '@material-ui/icons/Info';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import FindInPageIcon from '@material-ui/icons/FindInPage';

const styleFn = theme => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
  },
  toolbar: theme.mixins.toolbar,
  closeButton: {
    marginRight: 'auto',
    marginLeft: 0,
  },
  link: {
    display: 'block',
    textDecoration: 'none',
    color: 'inherit',
  },
  active: {
    backgroundColor: theme.palette.background.default,
  },
});

function CustomDrawer(props) {
  const {
    classes,
    className,
    drawerOpen,
    onCloseClick,
    loggedIn,
    onLoginClick,
    onLogoutClick,
  } = props;

  const isActive = ({ isCurrent }) => ({
    className: clsx(classes.link, isCurrent && classes.active),
  });

  return (
    <Drawer
      ModalProps={{ onBackdropClick: onCloseClick }}
      classes={{ paper: className }}
      variant="temporary"
      anchor="left"
      open={drawerOpen}
    >
      <IconButton onClick={onCloseClick} className={classes.closeButton}>
        <CloseIcon />
      </IconButton>
      <Divider />
      <List>
        {!loggedIn && (
          <ListItem button onClick={onLoginClick}>
            <ListItemIcon>
              <VpnKeyIcon />
            </ListItemIcon>
            <ListItemText primary="Login" />
          </ListItem>
        )}
        {loggedIn && (
          <ListItem button onClick={onLogoutClick}>
            <ListItemIcon>
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        )}
      </List>
      <Divider />
      {loggedIn && (
        <List>
          <Link getProps={isActive} to="manageAddresses" onClick={onCloseClick}>
            <ListItem button>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="Addresses" />
            </ListItem>
          </Link>
          <Link getProps={isActive} to="billing" onClick={onCloseClick}>
            <ListItem button>
              <ListItemIcon>
                <PaymentIcon />
              </ListItemIcon>
              <ListItemText primary="Billing" />
            </ListItem>
          </Link>
        </List>
      )}
      <Divider />
      <List>
        <Link getProps={isActive} to="docs" onClick={onCloseClick}>
          <ListItem button>
            <ListItemIcon>
              <FindInPageIcon />
            </ListItemIcon>
            <ListItemText primary="Documentation" />
          </ListItem>
        </Link>
        <Link getProps={isActive} to="faq" onClick={onCloseClick}>
          <ListItem button>
            <ListItemIcon>
              <InfoIcon />
            </ListItemIcon>
            <ListItemText primary="FAQ" />
          </ListItem>
        </Link>
        <Link getProps={isActive} to="support" onClick={onCloseClick}>
          <ListItem button>
            <ListItemIcon>
              <ContactSupportIcon />
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
        </Link>
      </List>
    </Drawer>
  );
}

export default withStyles(styleFn)(CustomDrawer);
