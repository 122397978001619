import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Chip from '../components/chip';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InfoIcon from '@material-ui/icons/Info';
// import InputAdornment from "@material-ui/core/InputAdornment";

const debugMode = false;

const styleFn = theme => ({
  debug: {
    position: 'fixed',
    zIndex: 1200,
    top: 0,
    display: debugMode ? 'block' : 'none',
  },
  hidden: {
    visibility: 'hidden',
  },
  balanceActions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  removed: {
    display: 'none',
  },
  root: {
    height: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    padding: '20px',
  },
  section: {
    maxWidth: '900px',
    width: '100%',
    backgroundColor: theme.palette.background.default,
    margin: '20px 0',
  },
  sectionDetails: {
    display: 'flex',
    flexDirection: 'column',
  },
  addressRow: {
    display: 'flex',
    flexDirection: 'row',
    border: '1px solid #333',
    margin: '5px 0',
  },
  textField: {
    maxWidth: '58ch',
    marginBottom: '40px',
  },
  balance: {
    textAlign: 'center',
    fontSize: '3em',
  },
  detailHolder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  transactionSummary: {},
  transactionContent: {
    width: '100%',
  },
});

const hexRegex = /^(0x)?[0-9a-fA-F]*$/;
const addressRegex = /^0x[0-9a-fA-F]{40}$/;

function AddAddress(props) {
  const {
    classes,
    getProject,
    getAddresses,
    addAddress,
    getTransactions,
    addCard,
    removeCard,
    removeAddress,
  } = props;

  const [expandedSection, setExpandedSection] = React.useState(false);
  const [expandedTransaction, setExpandedTransaction] = React.useState(false);
  const [addressInputValid, setAddressInputValid] = React.useState(true);
  const [addressInputComplete, setAddressInputComplete] = React.useState(false);
  const [firstLoad, setFirstLoad] = React.useState(false);
  const [project, setProject] = React.useState({ balance: 0, threshold: 0, topUp: 0 });
  const [addresses, setAddresses] = React.useState([]);
  const [transactions, setTransactions] = React.useState([]);
  const [addressFilter, setAddressFilter] = React.useState('');
  const [transactionFilter, setTransactionFilter] = React.useState('');

  React.useEffect(() => {
    if (!firstLoad) {
      getProject().then(setProject);
      getAddresses().then(setAddresses);
      getTransactions().then(setTransactions);
      setFirstLoad(true);
    }
  });

  const handleTransactionExpansion = panel => (event, isExpanded) => {
    setExpandedTransaction(isExpanded ? panel : false);
  };

  const handleSectionExpansion = panel => (event, isExpanded) => {
    setExpandedSection(isExpanded ? panel : false);
  };

  const handleAddressFilterUpdate = ({ target }) => {
    const isEmpty = target.value.length === 0;
    const isHex = hexRegex.test(target.value);
    const isAddress = addressRegex.test(target.value);

    setAddressInputComplete(isAddress);
    setAddressInputValid(isEmpty || isHex);
    setAddressFilter(target.value);
  };

  const handleTransactionFilterUpdate = ({ target }) => {
    setTransactionFilter(target.value);
  };

  const handleAddAddress = () =>
    addAddress(addressFilter)
      .then(() => getAddresses())
      .then(setAddresses);

  const handleRemoveAddress = address =>
    removeAddress(address)
      .then(() => getAddresses())
      .then(setAddresses);

  const handleAddCard = () =>
    addCard()
      .then(() => getProject())
      .then(setProject);

  const handleRemoveCard = () =>
    removeCard()
      .then(() => getProject())
      .then(setProject);

  const filteredAddresses = addresses.filter(address =>
    address.toLowerCase().includes(addressFilter.toLowerCase()),
  );

  const filteredTransaction = transactions.filter(({ to, from, txId, status }) => {
    const text = transactionFilter.toLowerCase();

    return (
      to.toLowerCase().includes(text) ||
      from.toLowerCase().includes(text) ||
      txId.toLowerCase().includes(text) ||
      status.toLowerCase().includes(text)
    );
  });

  return (
    <div className={classes.root}>
      <ExpansionPanel
        square
        className={classes.section}
        expanded={expandedSection === `section_balance`}
        onChange={handleSectionExpansion(`section_balance`)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Project Balance</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.sectionDetails}>
          <Typography className={classes.balance}>${project.balance.toFixed(2)}</Typography>

          {project.card && (
            <div className={classes.detailHolder}>
              <Typography>Balance Threshold:</Typography>
              <Typography>${project.threshold.toFixed(2)}</Typography>
            </div>
          )}

          {project.card && (
            <div className={classes.detailHolder}>
              <Typography>Top Up Amount:</Typography>
              <Typography>${project.topUp.toFixed(2)}</Typography>
            </div>
          )}

          {project.card && (
            <div className={classes.detailHolder}>
              <Typography>Card:</Typography>
              <Typography>{project.card}</Typography>
            </div>
          )}

          <div className={classes.balanceActions}>
            {!project.card && (
              <Button variant="contained" onClick={handleAddCard}>
                Add Card
              </Button>
            )}
            {project.card && (
              <Button variant="contained" onClick={handleRemoveCard}>
                Remove Card
              </Button>
            )}
            {project.card && <Button variant="contained">Top Up</Button>}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        className={classes.section}
        expanded={expandedSection === `section_project`}
        onChange={handleSectionExpansion(`section_project`)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Project Details</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.sectionDetails}>
          <div className={classes.detailHolder}>
            <Typography>Project Name:</Typography>
            <Typography>{project.name}</Typography>
          </div>

          <div className={classes.detailHolder}>
            <Typography>Network:</Typography>
            <Typography>{project.network}</Typography>
          </div>

          <div className={classes.detailHolder}>
            <Typography>RPC URL:</Typography>
            <Typography>{project.rpc}</Typography>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        className={classes.section}
        expanded={expandedSection === `section_transactions`}
        onChange={handleSectionExpansion(`section_transactions`)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Project Transactions</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.sectionDetails}>
          <TextField
            className={classes.textField}
            value={transactionFilter}
            variant="outlined"
            fullWidth
            color="secondary"
            onChange={handleTransactionFilterUpdate}
            autoComplete="off"
            noValidate
            label="Transaction Filter"
            helperText="Filter transactions"
          />

          {filteredTransaction.map(({ from, to, fee, id, status, txId }) => (
            <ExpansionPanel
              key={id}
              square
              expanded={expandedTransaction === `transaction_${id}`}
              onChange={handleTransactionExpansion(`transaction_${id}`)}
            >
              <ExpansionPanelSummary
                classes={{ root: classes.transactionSummary, content: classes.transactionContent }}
                expandIcon={<ExpandMoreIcon />}
              >
                <Chip value={txId} />
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={classes.sectionDetails}>
                <div className={classes.detailHolder}>
                  <Typography>From:</Typography>
                  <Chip value={from} />
                </div>

                <div className={classes.detailHolder}>
                  <Typography>To:</Typography>
                  <Chip value={to} />
                </div>

                <div className={classes.detailHolder}>
                  <Typography>Fee:</Typography>
                  <Typography>{fee} wei</Typography>
                </div>

                <div className={classes.detailHolder}>
                  <Typography>Status:</Typography>
                  <Typography>{status}</Typography>
                </div>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </ExpansionPanelDetails>
      </ExpansionPanel>

      <ExpansionPanel
        square
        className={classes.section}
        expanded={expandedSection === `section_addresses`}
        onChange={handleSectionExpansion(`section_addresses`)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">Project Addresses</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className={classes.sectionDetails}>
          <TextField
            className={classes.textField}
            value={addressFilter}
            variant="outlined"
            fullWidth
            color="secondary"
            onChange={handleAddressFilterUpdate}
            error={!addressInputValid}
            autoComplete="off"
            noValidate
            label="Address"
            helperText={
              addressInputValid
                ? 'Enter an Ethereum address to add or filter'
                : 'Invalid characters'
            }
            InputProps={{
              endAdornment: addressInputComplete && !filteredAddresses.length && (
                <IconButton onClick={handleAddAddress} color="inherit">
                  <AddIcon />
                </IconButton>
              ),
            }}
          />

          <div className={classes.addressList}>
            {filteredAddresses.map(address => (
              <div key={address} className={classes.addressRow}>
                <Chip value={address} />
                <IconButton color="inherit" onClick={() => setTransactionFilter(address)}>
                  <InfoIcon />
                </IconButton>
                <IconButton color="inherit" onClick={() => handleRemoveAddress(address)}>
                  <DeleteForeverIcon />
                </IconButton>
              </div>
            ))}
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  );
}

export default withStyles(styleFn)(AddAddress);
