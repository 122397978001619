import { ProjectPromiseClient } from '../protos/squishy_grpc_web_pb';
import squishyPb from '../protos/squishy_pb';

import GrpcService from './grpc-service';
import RefreshAccessTokenInterceptor from './interceptors/refresh-access-token';

export default class ProjectService extends GrpcService {
  constructor(url, context) {
    super(ProjectPromiseClient, squishyPb, url, {
      unaryInterceptors: [new RefreshAccessTokenInterceptor(url, context)],
    });
    this.context = context;
  }

  listAddresses(request, metadata = {}) {
    Object.assign(metadata, this.context.metadata);
    return this.execUnaryCall(this.listAddresses.name, request, metadata);
  }

  addAddress(request, metadata = {}) {
    Object.assign(metadata, this.context.metadata);
    return this.execUnaryCall(this.addAddress.name, request, metadata);
  }

  removeAddress(request, metadata = {}) {
    Object.assign(metadata, this.context.metadata);
    return this.execUnaryCall(this.removeAddress.name, request, metadata);
  }

  listTransactions(request, metadata = {}) {
    Object.assign(metadata, this.context.metadata);
    return this.execUnaryCall(this.listTransactions.name, request, metadata);
  }
}

// this.mockTransactions = this.mockAddresses.flatMap(address => [
//   {
//     from: address,
//     to: '0xffffffffffffffffffffffffffffffffffffffff',
//     fee: '1483092000000000',
//     id: uuidv4(),
//     status: 'Received',
//     txId: '0x4fd6912e4bbad48d862746596a7b6baf82ec953e6678bc9329c1c76c1c902ffa',
//   },
//   {
//     from: address,
//     to: '0xffffffffffffffffffffffffffffffffffffffff',
//     fee: '1483092000000000',
//     id: uuidv4(),
//     status: 'Broadcasted',
//     txId: '0x4fd6912e4bbad48d862746596a7b6baf82ec953e6678bc9329c1c76c1c902ffa',
//   },
//   {
//     from: address,
//     to: '0xffffffffffffffffffffffffffffffffffffffff',
//     fee: '1483092000000000',
//     id: uuidv4(),
//     status: 'Mined',
//     txId: '0x4fd6912e4bbad48d862746596a7b6baf82ec953e6678bc9329c1c76c1c902ffa',
//   },
// ]);

// this.mockProject = {
//   balance: 15.0,
//   card: null,
//   name: 'Default',
//   network: 'Ropsten',
//   threshold: 10.0,
//   topUp: 20.0,
//   rpc: 'rpc.fuelstop.io/a445b324ad097ccf',
// };
